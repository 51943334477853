import React, { useState } from 'react';

import Layout from '../../components/organisms/layout/layout';
import WeContent from '../../components/organisms/we-content/we-content';

const NuestraFamilia = () => {
  const menuActive = 1;
  const [modalActive, setmodalActive] = useState(null)
  const LOCALE = process.env.LOCALE || "es-CO"
  let currentPage = (LOCALE === "es-PR") ? 2 : 1;
  let metaTitle = {
    "es-EC": "Conoce a nuestra Familia - Familia Hogar"
  }[LOCALE]
  let metaDescription = {
    "es-EC": "Conoce a nuestra familia de famiositos y su rol. Cada uno tiene algo que enseñarte sobre el máximo aseo e higiene en el hogar."
  }[LOCALE]

  return (
    <Layout menuActive={menuActive}
    setmodalActive={setmodalActive}
    modalActive={modalActive}
    title={
      process.env.LOCALE === 'es-CO'
        ? 'Conoce más sobre nuestra empresa - Familia Hogar'
        : process.env.LOCALE === 'es-EC'
        ? '¿Quiénes somos? Conócenos - Familia Hogar'
        : process.env.LOCALE === 'es-DO'
        ? 'Conoce más sobre nosotros - Familia Hogar'
        : process.env.LOCALE === 'es-PR'
        ? 'Conoce sobre nuestra marca - Familia hogar'
        : ''
    }
    slug={'/nosotros/nuestra-familia/'}
    >
      <WeContent 
        page={currentPage}
        metaTitulo={metaTitle || null}
        metaDescripcion={metaDescription || null}
      />
    </Layout>
  );
};

export default NuestraFamilia;
